/**
 * @NOTE only for buttons
 *
 * styles: Spinner.scss
 * @TODO support for different elements
 *
 * Usage:
 * import { Spinner } from "../components/Spinner";
 *
 * On click button, for example before ajax call:
 * Spinner(e.target).append(); //e.target - your button
 *
 * On ajax success or when you want to remove the spinner:
 * Spinner($("YOUR_BUTTON_SELECTOR")).remove();
 *
 **/

export const Spinner = (() => {
  class Spinner {
    constructor($element) {
      this.$selector = $($element)
      this.className = 'spinner'

      this.prepend = this.prepend.bind(this)
      this.append = this.append.bind(this)
      this.remove = this.remove.bind(this)
    }

    spinner() {
      return $(
        `<div class="${this.className}"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`
      )
    }

    prepend() {
      const $this = this.$selector.find(`.${this.className}`)
      !$this.length && this.$selector.prepend(this.spinner().addClass('prepend'))
    }

    append() {
      const $this = this.$selector.find(`.${this.className}`)
      !$this.length && this.$selector.append(this.spinner().addClass('append'))
    }

    remove() {
      const $this = this.$selector.find(`.${this.className}`)
      $this.length && $this.remove()
    }
  }

  return ($element) => new Spinner($element)
})()
